import React from 'react'
import cookies from 'react-cookies'
const JoinPageContent = ({ executeScroll }) => {
  let user = cookies.load('user')
  return (
    <div className="container mx-auto py-20">
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="flex-none lg:flex-1 aspect-[3/4] lg:h-auto">
            <div
              className="w-full h-full bg-cover bg-no-repeat rounded relative shadow"
              style={{ backgroundImage: `url(/images/join-page-man.png)` }}
            >
              <img
                src="/icons/join-page-vector-blue.svg"
                className="hidden lg:block absolute bottom-0 left-0 z-10 home-hero-vector-red -ml-20 -mb-20"
              />
            </div>
          </div>
          <div className="flex-1 pt-0 lg:pt-32">
            <div className="w-full overlay-div bg-white px-8 lg:px-12 py-8 shadow lg:-ml-20 relative">
              <p className="text-headingFour lg:text-headingThree font-playfair font-normal mb-2">
                90% would recommend MedReps
              </p>
              <p className="text-base font-sans">
                to a friend looking for medical sales jobs
              </p>
            </div>
            <div className="qoute-div pt-8 lg:pt-32">
              <img src="/images/qoute.png" className="pt-4" />
              <p className="font-sans text-lg font-normal leading-45">
                I have been successful using MedReps in my last three job
                searches.
              </p>
              <p className="font-sans text-lg font-bold pt-4">
                Anthony O| 2021
              </p>
              {!user && (
                <button
                  className="text-white py-3 px-10 bg-button cursor-pointer font-sans font-bold rounded text-base"
                  onClick={() => {
                    executeScroll()
                  }}
                >
                  Join Now!
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row mt-16 lg:mt-32">
          <div className="w-5/5 lg:w-3/5 pl-0 lg:pl-12">
            <h1 className="font-playfair font-normal text-headingFour lg:text-headingTwo mb-0">
              MedReps Membership includes
            </h1>
            <ul className="pt-6 lg:pt-20 ml-0 pl-0">
              <li className="flex my-1">
                <span>
                  <img
                    className="w-4.5 h-4.5 lg:w-6 lg:h-6"
                    src="/icons/check-with-circle.svg"
                  />
                </span>
                <span className="flex-1 font-normal font-sans text-sm lg:text-lg ml-4 mb-0">
                  Unlimited access to medical sales jobs from top employers
                </span>
              </li>
              <li className="flex my-1">
                <span>
                  <img
                    className="w-4.5 h-4.5 lg:w-6 lg:h-6"
                    src="/icons/check-with-circle.svg"
                  />
                </span>
                <span className="flex-1 font-normal font-sans text-sm lg:text-lg ml-4 mb-0">
                  Detailed, up to date medical sales salary information
                </span>
              </li>
              <li className="flex my-1">
                <span>
                  <img
                    className="w-4.5 h-4.5 lg:w-6 lg:h-6"
                    src="/icons/check-with-circle.svg"
                  />
                </span>
                <span className="flex-1 font-normal font-sans text-sm lg:text-lg ml-4 mb-0">
                  A searchable profile visible to medical sales employers and
                  recruiters
                </span>
              </li>
              <li className="flex my-1">
                <span>
                  <img
                    className="w-4.5 h-4.5 lg:w-6 lg:h-6"
                    src="/icons/check-with-circle.svg"
                  />
                </span>
                <span className="flex-1 font-normal font-sans text-sm lg:text-lg ml-4 mb-0">
                  Insider career advice from employers and recruiters, available
                  exclusively to MedReps members
                </span>
              </li>
            </ul>
          </div>
          <div className="w-5/5 lg:w-2/5 relative">
            <div
              className="w-full h-full bg-contain bg-no-repeat rounded relative h-96"
              style={{
                backgroundImage: `url(/images/membership-includes.png)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default JoinPageContent
