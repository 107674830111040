import React from 'react'
const JoinLastMonths = () => {
  return (
    <div className="bg-themePink w-full py-12 lg:py-24">
      <div className="container mx-auto">
        <h1 className="text-center text-headingFour lg:text-headingTwo font-playfair font-normal pb-3">
          In the last 6 months
        </h1>
        <div className="w-4/5 gap-4 mx-auto flex flex-col md:flex-row justify-between">
          <div className="w-100 md:w-48% self-stretch">
            <div className="bg-white w-full h-full flex flex-col items-center shadow p-8">
              <div className="min-h-5">
                <img src="/icons/fluent-laptop.svg" className="mb-0" />
              </div>
              <p className="mb-0 text-button text-headingTwo font-bold">50%</p>
              <p className="mb-0 text-lg font-sans text-center">
                received a job offer{' '}
              </p>
            </div>
          </div>
          <div className="w-100 md:w-48% self-stretch justify-center">
            <div className="bg-white w-full h-full flex flex-col items-center shadow p-8">
              <div className="min-h-5">
                <img src="/icons/bi-person.svg" className="mb-0" />
              </div>
              <p className="mb-0 text-button text-headingTwo font-bold">85%</p>
              <p className="mb-0 text-lg font-sans text-center">
                had an interview
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default JoinLastMonths
