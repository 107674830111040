import React, { useEffect, useRef } from 'react'
import Wrapper from '../components/wrapper'
import JoinNowHero from '../components/pageComponents/joinPageHero'
import PageNav from '../components/pageComponents/common/pageNav'
import EmployerSlider from '../components/pageComponents/employerSlider'
import JoinLastMonths from '../components/pageComponents/joinLastData'
import JoinPageContent from '../components/pageComponents/joinPageContent'
import useGMT from '../hooks/useGTM'

const JoinNowPage = ({ location }) => {
  const { pushCandidateViewItemEvent } = useGMT()
  const joinNowRef = useRef(null)
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const executeScroll = () => scrollToRef(joinNowRef)

  useEffect(() => {
    pushCandidateViewItemEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Children = props => {
    return (
      <>
        <PageNav navItem="Membership Sign Up" />
        <JoinNowHero {...props} joinNowRef={joinNowRef} />
        <EmployerSlider {...props} />
        <JoinLastMonths {...props} />
        <JoinPageContent {...props} executeScroll={executeScroll} />
      </>
    )
  }
  return (
    <Wrapper
      location={location}
      hideCTA={true}
      title="Join Now | MedReps"
      description="Join MedReps now and become a member to gain unlimited access to a diverse range of medical sales job opportunities, career advice and salary information."
    >
      <>
        <Children />
      </>
    </Wrapper>
  )
}
export default JoinNowPage
