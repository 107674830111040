import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Form, Input, message, Checkbox } from 'antd'
import { auth0Roles, strapiURL } from '../../../config'
import { formatPrice } from '../../../functions'
import cookie from 'react-cookies'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import globalData from '../../../../globalData.json'
import { useDispatch } from 'react-redux'
import { setProfileData, setProducts } from '../../../redux/actions/user'
import useGMT from '../../../hooks/useGTM'

const JoinNowHero = ({ joinNowRef }) => {
  const websiteTimeQuery = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
    }
  `)
  const buildTime = new Date(websiteTimeQuery.site.buildTime).toISOString()
  const [step, setStep] = useState(1)
  const [listingPrices, setListingPrices] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [activeJobCount, setActiveJobCount] = useState(0)
  const [candidateForm] = Form.useForm()
  const dispatch = useDispatch()

  const { pushCandidateCheckoutEvent, pushCandidateSelectItemEvent } = useGMT()

  const navbarItems = [
    {
      label: 'Select Plan',
      id: 1,
    },
    {
      label: 'Personal Info',
      id: 2,
    },
    {
      label: 'Make Payment',
      id: 3,
    },
  ]

  useEffect(() => {
    setLoading(true)
    let _prices = globalData?.CandidateCatalog?.prices?.length
      ? globalData?.CandidateCatalog?.prices
      : []
    setListingPrices(_prices)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (subscription) {
      pushCandidateSelectItemEvent(subscription)
    }
  }, [subscription])
  useEffect(() => {
    // Get Active Job Count
    axios
      .get(strapiURL + `/jobs/count/active?buildTime=${buildTime}`)
      .then(res => {
        setActiveJobCount(res?.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const signUp = values => {
    pushCandidateCheckoutEvent(subscription)

    setSubmitLoading(true)
    let data = {
      email: values.email,
      password: values.password,
      last_login: new Date(),
      roleId: auth0Roles.Member.id,
      firstName: values.firstName,
      lastName: values.lastName,
      name: values.firstName + ' ' + values.lastName,
      is_newsletter_subscriber: values?.is_newsletter_subscriber || false,
    }

    axios
      .post(strapiURL + '/auth0/signup', data)
      .then(res => {
        const userData = res.data
        const responseRole = userData.role.id
        let profile = userData?.profile
        delete userData.profile

        cookie.save('userRole', responseRole, { path: '/', maxAge: 259200 })
        cookie.save('user', userData, { path: '/', maxAge: 259200 })

        dispatch(setProfileData(profile))

        if (!subscription && typeof window !== 'undefined') {
          window.location.href = '/member-dashboard'
        } else if (!userData.isCheckoutRequired) {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL +
                `/employers/${userData?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/member-dashboard/', {
                state: { firstRegistration: true },
              })
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        } else {
          if (typeof window !== 'undefined') {
            let _checkoutPrices = []
            _checkoutPrices.push({
              price: subscription,
              quantity: 1,
            })

            localStorage.setItem('can-subscription', subscription)

            axios
              .post(strapiURL + '/stripe/session/checkout', {
                line_items: _checkoutPrices,
                success_url:
                  window.location.origin +
                  '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}&first_registration=true',
                cancel_url: window.location.origin,
                customerEmail: values?.email || '',
                mode: 'subscription',
                clientId: cookie.load('_ga')?.substring(6),
                sessionId: cookie
                  .load(
                    process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
                      'G-',
                      '_ga_'
                    )
                  )
                  ?.split('.')[2],
              })
              .then(res => {
                if (typeof window !== 'undefined') {
                  window.history.replaceState(
                    '',
                    '',
                    `${window.location.origin}/thankyou/`
                  )
                  window.location.href = res?.data?.url
                }
              })
              .catch(err => {
                message.error('Something wrong while checkout.')
              })
          }
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message[0].error)
        setSubmitLoading(false)
      })
  }
  const CandidateForm = () => {
    return (
      <div>
        <Form
          form={candidateForm}
          name="candidate-form"
          layout="vertical"
          onFinish={signUp}
        >
          <Form.Item
            name="email"
            label="Email"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First name"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last name"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            label="Confirm password"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="agree"
            className="theme-form-item mb-2"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('Required')),
              },
            ]}
          >
            <Checkbox>
              {' '}
              I have read and agree to the MedReps{' '}
              <a href="/privacy-policy" target="_blank">
                Terms & Conditions
              </a>
            </Checkbox>
          </Form.Item>
          <p className="pt-0 font-sans text-last mb-4">
            By joining Medreps.com you agree to receive communications from time
            to time to keep you informed of site updates as it pertains to your
            membership.
          </p>
          <Form.Item
            className="theme-form-item mb-4"
            name="is_newsletter_subscriber"
            valuePropName="checked"
          >
            <Checkbox name="subscribe" className="font-semibold font-sans">
              Subscribe to The Brief, MedReps' newsletter featuring latest
              medical sales career and industry insights
            </Checkbox>
          </Form.Item>
          <button
            type="submit"
            className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
          >
            {submitLoading
              ? 'Loading...'
              : subscription
              ? 'Checkout'
              : 'Signup'}
          </button>
        </Form>
      </div>
    )
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <div ref={joinNowRef} id="join-now" className="container mx-auto">
        <div className="w-full lg:w-4/5 mx-auto flex flex-col lg:flex-row items-center">
          <div className="w-full lg:w-2/5 hidden lg:block">
            <div className="bg-themePink rounded-lg p-16 flex flex-col items-center">
              <img src="/icons/add-user.svg" />
              <p className="font-playfair text-headingOne">Join Now</p>
              <p className="font-sans text-base text-center">
                Unlimited access to medical sales jobs and career resources
              </p>
              <div className="w-24 h-px mx-auto bg-merlot mt-12"></div>
              <span className="text-headingThree font-playfair text-center mt-4">
                {activeJobCount}
              </span>
              <p className="text-base font-sans font-normal">
                jobs available today!
              </p>
            </div>
          </div>
          {/* <div></div> */}
          {loading ? (
            <div
              className="w-full lg:w-3/5 min-h-700px bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(/loaders/products-loader.png)` }}
            />
          ) : (
            <div className="mx-auto w-full lg:w-3/5 pl-0 lg:pl-16">
              {/* navbar Area */}
              {step > 0 && (
                <div className="mx-auto w-[80%] lg:w-full navbar-area">
                  <div className="flex items-center relative">
                    {step > 0 ? (
                      <img
                        onClick={() => {
                          if (step > 1) {
                            setStep(step - 1)
                          } else {
                            navigate('/')
                          }
                        }}
                        src="/icons/back.svg"
                        className="w-8 -ml-8 absolute left-0 cursor-pointer"
                      />
                    ) : (
                      <div />
                    )}
                    <h1 className="text-lg font-sans font-semibold text-merlot">
                      Member Sign Up
                    </h1>
                  </div>
                  <div className="w-[80%] lg:w-full flex items-center">
                    {navbarItems?.map((navItem, navIndex) => {
                      return (
                        <>
                          <div className="membership-step" key={navIndex}>
                            {step >= navItem.id ? (
                              <div className="active h-6 w-6 bg-merlot rounded-full flex items-center justify-center">
                                <div className="h-2 w-2 bg-white rounded-full" />
                              </div>
                            ) : (
                              <div className="inactive bg-border h-6 w-6 bg-[#E1E1E1] rounded-full flex items-center justify-center" />
                            )}

                            <div className="absolute">
                              <p className="w-full text-xs font-semibold font-sans w-max mt-4">
                                {navItem.label}
                              </p>
                            </div>
                          </div>
                          {navIndex !== navbarItems?.length - 1 && (
                            <div className="divider w-85 h-px bg-border" />
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              )}
              {/* navbar Area */}
              {/* Steps Area */}
              {/* Step 1 */}
              {step === 1 && (
                <div className="pt-16 mx-auto w-11/12 lg:w-full">
                  <h1 className="font-playfair font-normal text-headingThree mb-2">
                    Choose your membership cycle
                  </h1>
                  <p className="text-sm font-sans">
                    We think it's a good idea to stay plugged into the job
                    market, so unless you request otherwise, your membership
                    will automatically renew
                  </p>
                  <div className="options-div">
                    <div className="options-div w-full">
                      {listingPrices?.map((price, priceIndex) => {
                        let isSelected = subscription === price?.price?.priceId
                        return (
                          <div
                            onClick={() => {
                              if (subscription === price?.price?.priceId) {
                                setSubscription(null)
                                return
                              }
                              setSubscription(price?.price?.priceId)
                            }}
                            key={priceIndex}
                            className={`mb-3 single-option bg-white shadow-md flex justify-between items-center p-4 rounded-md cursor-pointer border-2 border-transparent ${
                              isSelected
                                ? 'border-2 border-merlot active-card-shadow'
                                : ''
                            }`}
                          >
                            <div className="flex items-center">
                              {isSelected ? (
                                <div className="flex items-center justify-center active bg-merlot h-6 md:h-9 w-6 md:w-9 bg-merlot rounded-full flex items-center justify-center">
                                  <img
                                    src="/icons/check-mark.svg"
                                    className="mb-0"
                                  />
                                </div>
                              ) : (
                                <div className="inactive bg-border h-6 md:h-9 w-6 md:w-9 bg-merlot rounded-full flex items-center justify-center" />
                              )}

                              <div className="ml-4">
                                <p className="font-sans font-bold text-lg mb-0">
                                  {price?.price?.name || price?.name} membership
                                </p>
                                <p className="font-sans text-xs mb-1"></p>
                              </div>
                            </div>
                            <p className="font-sans font-bold text-lg mb-0">
                              {formatPrice(price?.price?.amount, 'USD')}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {subscription && (
                    <button
                      onClick={() => {
                        setStep(step + 1)
                      }}
                      className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                    >
                      Next
                    </button>
                  )}
                </div>
              )}
              {/* Step 1 */}
              {/* Step 2 */}
              {step === 2 && (
                <div className="pt-16 w-11/12">
                  <CandidateForm />
                </div>
              )}
              {/* 2 */}
              {/* Steps Area */}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default JoinNowHero
